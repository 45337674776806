import React from "react";
import { Link } from "react-router-dom";
import "../styles/main.css";

const PinkBgBtn = (props) => {
  return (
    <Link
      onClick={props.onClick}
      target={props.target}
      to={props.to}
      className="pink-bg-link pink-bg-link-icon"
    >
      <span>{props.text}</span> <span className="icon">{props.icon}</span>
    </Link>
  );
};

export default PinkBgBtn;
