import React from 'react'
import { Link } from 'react-router-dom'
import "../styles/team/team.css"
import "../styles/forms/forms.css"
import Footer from '../components/Footer'
import GeneralNavBar from '../components/GeneralNavBar'


const team = () => {
  return (
    <div className='main-container'>
        <GeneralNavBar logo="/images/logo.png"/>
        <section className="form-hero">

            <div className="container padding-container">
            <div className="form-hero-main-container">
                <h1>Forms and Resources</h1>
               
            </div>
            </div>
            
        </section>
        <section className="main-form">
            <div className="container padding-container">
                <h1>Download our resources and forms below</h1>

                <div className="forms">
                    <div className="form">
                        <h2 className='form-title'>Workers Comp Set Up</h2>
                        <Link to="https://www.firstmed.health/assets/volumes/staff/Work-Comp-Account-Set-Up-revised-12.19.pdf" target='_blank'>Download</Link>
                    </div>
                    
                </div>
            </div>
        </section>
        
        <Footer logo="/images/logo.png"/>
    </div>
  )
}

export default team