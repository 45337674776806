import React from "react";
import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import { HiXMark } from "react-icons/hi2";
import { useState } from "react";
const TeamCard = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  const showPopupFn = () => {
    setShowPopup(true);
  };
  const hidePopopFn = () => {
    setShowPopup(false);
  };

  return (
    <div className="card-wrapper">
      {showPopup && <div className="popup-overlay" onClick={hidePopopFn}></div>}
      <div className="team-card">
        <div className="team-card-overlay">
          <div className="bio-content">{props.bio}</div>
          <div className="links">
            <button className="read-more" onClick={showPopupFn}>
              Read more
            </button>
          </div>
        </div>
        <img src={props.img} alt="Employee" />
        <div className="info">
          <h4>{props.name}</h4>
          <p>{props.position}</p>
        </div>
      </div>

      {showPopup && (
        <div className="popup">
          <div className="row">
            <div className="img-container">
              <img src={props.img} alt="Employee" />
            </div>
            <div className="col">
              <h1 className="name">{props.name}</h1>
              <h3 className="position">{props.position}</h3>
            </div>
          </div>

          <HiXMark className="x-icon" onClick={hidePopopFn} />
          <p>{props.bio}</p>
        </div>
      )}
    </div>
  );
};

export default TeamCard;
