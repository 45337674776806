import React from "react";

const PrimaryCareFacilityPopup = ({ operationHours, onClose, location }) => {
  return (
    <div className="facility-popup">
      <div className="overlay" onClick={onClose}></div>
      <div className="popup-content">
        <h2 className="title">Book your appointment</h2>
        <hr />
        <button className="close-btn" onClick={onClose}>
          ×
        </button>
        <h4>Location Services</h4>
        <p>{location}</p>

        <h4>Hours of Operation</h4>
        <div>{operationHours}</div>
      </div>
    </div>
  );
};

export default PrimaryCareFacilityPopup;
