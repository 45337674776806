import React from "react";
import { FaCircleExclamation } from "react-icons/fa6";
import PinkBgBtn from "./PinkBgBtn";
import { FaArrowRightLong } from "react-icons/fa6";
import "../styles/main.css";

const HealthPartner = () => {
  return (
    <section className="health-partner">
      <div className="container padding-container">
        <div className="health-main-container">
          <img
            className="left-eclipse"
            src="/images/virtual-left.svg"
            alt="Eclipse"
          />
          <img
            className="right-eclipse"
            src="/images/virtual-right.svg"
            alt="Eclipse"
          />
          <h1>Virtual Visit</h1>

          <p>Book your virtual appointment today!</p>

          <PinkBgBtn
            to="https://www.solvhealth.com/r/book-online/A2GbMp/0Z5_o_2hRLBYTI1zfYMVO/slots/today"
            className="health-btn"
            text="Book Now"
            icon={<FaArrowRightLong />}
          />
        </div>
      </div>
    </section>
  );
};

export default HealthPartner;
