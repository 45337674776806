import React from "react";

const Insurance = () => {
  return (
    <section className="insurance">
      <div className="container padding-container">
        <div className="insurance-first">
          <h1>Insurance</h1>
          <p>
            Insurance or no insurance – no problem. We accept most major
            insurance plans and have private pay options available.
          </p>
        </div>
        <div className="insurance-img-container">
          <img src="/images/insurance.png" alt="People looking at document" />
        </div>
      </div>
    </section>
  );
};

export default Insurance;
