import React from "react";

import "../styles/careers.css";
import CareerCard from "../components/CareerCard";
import { useState, useEffect } from "react";

import GeneralNavBar from "../components/GeneralNavBar";
import Footer from "../components/Footer";
import PinkBgBtn from "../components/PinkBgBtn";
import "../styles/team/team.css";

const Careers = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data from an API endpoint
        const response = await fetch(
          "https://recruiting.paylocity.com/recruiting/v2/api/feed/jobs/e8cd713b-10b1-4225-af85-ffb0fe826196"
        );

        // Convert response to JSON
        const jsonData = await response.json();

        // Update state with the fetched data
        setData(jsonData.jobs);
        console.log(jsonData.jobs[0].requirements);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function
    fetchData();
  }, []);

  return (
    <div className="main-container">
      <GeneralNavBar logo="/images/logo.png" />

      <section className="career-hero">
        <div className="container padding-container">
          <div className="career-hero-main-container">
            <h1>Careers</h1>
            <p>
              Interested in a healthcare career? <br /> Your journey starts
              here:
            </p>
            <PinkBgBtn
              to="mailto:aanderson@chmcok.com"
              text="Submit your Resume"
            />
          </div>
        </div>
      </section>

      <section className="data">
        <div className="container padding-container">
          <div className="data-card-container">
            {data ? (
              <>
                {data.map((item) => (
                  <CareerCard
                    key={item.jobId}
                    jobPosition={item.title}
                    jobDescription={item.description}
                    jobLocation={
                      item.jobLocation.address
                        ? item.jobLocation.address
                        : "Unspecified"
                    }
                    time="Full Time"
                    link={item.applyUrl}
                    jobRequirements={item.requirements}
                  />
                ))}
              </>
            ) : (
              <p>Loading...</p>
            )}
          </div>
        </div>
      </section>

      <section className="next-move">
        <div className="container padding-container">
          <h1>Ready For Your Next Move ?</h1>
          <p>A career at FirstMed could be around the corner.</p>
          <PinkBgBtn
            to="mailto:aanderson@chmcok.com"
            text="Send Us Your Resume"
          />
        </div>
      </section>

      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default Careers;
