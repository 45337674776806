import React from 'react'
import { RiShieldCheckFill } from "react-icons/ri";
const PrimaryService = (props) => {
  return (
    <div className="primary-card">
        <div className="shield-icon-container">
        <RiShieldCheckFill className='shield-icon'/>
        </div>
        
        <p>{props.text}</p>
    </div>
  )
}

export default PrimaryService