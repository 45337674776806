import React from 'react'
import { FaQuoteLeft } from "react-icons/fa";

const Testimonial = () => {
  return (
        <section className="testimonial">
            <div className="container padding-container">
            <h1>Testimonials</h1>
            <div className="testimonial-cards">
                <div className="testimonial-card">
                    <div className="quote-icon-container">
                    <FaQuoteLeft className='quote-icon'/>

                    </div>
                    <div className="testimonial-content">
                        <p>Love that they have an app and the SMS alert and option to schedule the appointment same day or for next day when after hours.</p>
                        <h5>KATHY</h5>
                    </div>
                </div>
                <div className="testimonial-card">
                <div className="quote-icon-container">
                    <FaQuoteLeft className='quote-icon'/>

                    </div>
                    <div className="testimonial-content">
                        <p>Got an appointment quickly. Very nice. She is attentive and listened to all of my concerns.</p>
                        <h5>HEATHER</h5>
                    </div>
                </div>
                <div className="testimonial-card">
                <div className="quote-icon-container">
                    <FaQuoteLeft className='quote-icon'/>

                    </div>
                    <div className="testimonial-content">
                        <p>My kids and I have been seeing Dr. Craig-Goodell for several years. She’s exceptionally kind and wonderful with children. The office is very quick to get prescriptions refilled (same day typically) and they are pretty efficient with their records and other processes as well. We tried several other doctors (4-5 pediatricians) before finding her. I’m grateful.</p>
                        <h5>WILLIAM</h5>
                    </div>
                </div>
            </div>
            </div>
            
        </section>
  )
}

export default Testimonial