import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import { HiOutlineXMark } from "react-icons/hi2";
const GeneralNavBar = (props) => {
  const [scrolled, setScrolled] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("DOMContentLoaded", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const navClasses = `${"general-header"} ${
    scrolled ? "scrolled" : "general-header"
  }`;

  return (
    <header className={navClasses}>
      <div className="container padding-container">
        <Link to="/">
          <img src={props.logo} alt="Logo" />
        </Link>

        <nav>
          <ul className={`${isOpen ? "navOpen" : ""}`}>
            <li>
              <Link
                to="/"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/urgent-care"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Urgent Care
              </Link>
            </li>
            <li>
              <Link
                to="/graham-family-med"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Graham Family Med
              </Link>
            </li>
            <li>
              <Link
                to="/team"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Our Team
              </Link>
            </li>
            <li>
              <Link
                to="/occupational"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Occupational Med
              </Link>
            </li>
            <li>
              <Link
                to="/careers"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/forms"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Forms
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                className="link"
                onClick={() => {
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  });
                }}
              >
                Contact
              </Link>
            </li>
          </ul>
          <div
            className={`hamburger-container ${
              isOpen ? "hidden" : "hamburger-container"
            }`}
            onClick={toggleMenu}
          >
            <RxHamburgerMenu className="hamburger" />
          </div>
          <div
            className={`close-hamburger-container ${
              !isOpen ? "hidden" : "hamburger-container"
            }`}
            onClick={toggleMenu}
          >
            <HiOutlineXMark className="close-hamburger" />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default GeneralNavBar;
