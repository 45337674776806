import React from "react";
import GeneralNavBar from "../components/GeneralNavBar";
import PinkBgBtn from "../components/PinkBgBtn";
import BorderBtn from "../components/BorderBtn";
import "../styles/primary-care/primary-care.css";
import FacilitiesCard from "../components/FacilitiesCard";
import PrimaryService from "../components/PrimaryService";
import Insurance from "../components/Insurance";
import HealthPartner from "../components/HealthPartner";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";
import PrimaryCareFacilityPopup from "../components/PrimaryCareFacilityPopup";
const PrimaryCare = () => {
  const [primaryPopup, setPrimaryPopup] = useState(null);

  const openPrimaryPopup = (info) => {
    setPrimaryPopup(info);
  };
  const closePrimaryPopup = () => {
    setPrimaryPopup(null);
  };
  return (
    <div className="main-container">
      <GeneralNavBar logo="/images/logo.png" alt="Logo" />
      <section className="primary-hero">
        <div className="container padding-container">
          <div className="primary-hero-main-container">
            <div className="row">
              {/* <div>
                <img
                  src="/images/primary-care-big-logo.png"
                  alt="Primary Care logo"
                />
              </div> */}
              <div>
                <img
                  src="/images/family-medicine-logo.png"
                  alt="Cohesive Family Medicine logo"
                />
              </div>
            </div>
            <p>
              Our mission is to uniquely connect patients to convenient and
              compassionate care in the communities we serve.
            </p>
          </div>
        </div>
      </section>
      <section className="primary-facility">
        <div className="container">
          <div className="facility-text-content">
            <h1>Our facilities</h1>
            <p>
              With 2 Primary Care locations to serve you – you’re more than a
              patient, you’re our neighbor. Skip the line by booking an
              appointment.
            </p>
          </div>
          <div className="facilities-card-container">
            <FacilitiesCard
              facility="Primary Care - Cohesive Family Medicine
              "
              address="2508 N. Harrison St."
              location="Shawnee, OK 74804"
              img="/images/cohesive-medicine-facility.webp"
              appointment="Monday, Feb 26 | 7:30Pm"
              onClick={() =>
                openPrimaryPopup({
                  facility: "Primary Care - Eastern",
                  address: "13310 N. Eastern Ave.",
                  location: "TBA",

                  operationHours: <p>TBA</p>,
                })
              }
            />
            <FacilitiesCard
              facility="Primary Care - Eastern"
              address="13310 N. Eastern Ave."
              location="Oklahoma City, OK 73131"
              img="/images/primare-care-facility.webp"
              appointment="Monday, Feb 26 | 7:30Pm"
              onClick={() =>
                openPrimaryPopup({
                  facility: "Primary Care - Eastern",
                  address: "13310 N. Eastern Ave.",
                  location:
                    "Adult Primary Care Services Accepts Medicare and most commercial insurances",

                  operationHours: (
                    <p>
                      Monday - Thursday 8:30am - 12pm, 1pm - 3pm <br />
                      Closed Lunch 12pm - 1pm <br />
                      Friday - 830am - 1pm
                    </p>
                  ),
                })
              }
            />
            {/* <FacilitiesCard facility="First Med Primary Care" address="13310 N Eastern Ave" location="OKC, OK 73131" img="/images/primare-care-facility.jpg" appointment="Monday, Feb 26 | 7:30Pm"/> */}
          </div>
          {primaryPopup && (
            <PrimaryCareFacilityPopup
              {...primaryPopup}
              onClose={closePrimaryPopup}
            />
          )}
        </div>
      </section>
      <section className="primary-care-services">
        <div className="container padding-container">
          <h1>Our Primary Care Services</h1>
          <div className="primary-card-container">
            <PrimaryService text="Urinary & Gynecological Infections" />
            <PrimaryService text="Cold, Flu & Allergies" />
            <PrimaryService text="Musculoskeletal Injuries" />
            <PrimaryService text="Onsite Lab" />
            <PrimaryService text="Sport Physicals" />
            <PrimaryService text="Skin Conditions" />
          </div>
        </div>
      </section>
      <Insurance />
      <section className="app-download">
        <div className="container padding-container">
          <span>Ask our staff to send you the email invitation!</span>
          <p>
            To access the patient portal, you'll need to download the app.{" "}
            <br /> You can find the app in the appropriate store for your
            device, whether it's the App Store or Google Play.
          </p>
          <div className="primary-hero-button">
            <div className="icons-container">
              <Link to="https://apps.apple.com/us/app/myhealthspot/id680054073">
                <img src="/images/appstore.png" alt="App store Icon" />
              </Link>
              <span>Download from Apple App Store</span>
            </div>
            <div className="icons-container" id="google-icon">
              <Link to="https://play.google.com/store/apps/details?id=com.azaleahealth.azaleam.prod">
                <img src="/images/google.png" alt="Google Play Store Icon" />
              </Link>
              <span>Download from Google Play Store</span>
            </div>
          </div>
        </div>
      </section>
      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default PrimaryCare;
