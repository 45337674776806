import React from "react";
import "../styles/contact.css";
import { IoPersonOutline } from "react-icons/io5";
import { SiMinutemailer } from "react-icons/si";
import { IoCallOutline } from "react-icons/io5";
import { PiSpotifyLogoThin } from "react-icons/pi";
import { AiOutlineMessage } from "react-icons/ai";
import TransparentNav from "../components/TransparentNav";
import Footer from "../components/Footer";
import PinkBgBtn from "../components/PinkBgBtn";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// comment
const Contact = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);
  return (
    <div className="main-contact-container">
      <TransparentNav logo="/images/logo.png" />
      <section className="team-contact">
        <div className="contact-main-container container padding-container">
          <div className="img-text">
            <h1>Healing First Begins With Connection</h1>
            <p>
              Your thoughts and inquiries are important to us, and we’re here to
              help. Please use the form below to send us a message, and we’ll
              get back to you as soon as possible.
            </p>
          </div>
        </div>
      </section>

      <section className="contact-section" id="contact-us">
        <div className="container padding-container">
          <h1>Contact Us</h1>
          <div className="main-contact-container">
            <div className="first-container">
              <p>
                At FirstMed, we care about you and want to make sure you have an
                exceptional experience when you come to us for care.
              </p>
              <div className="pink-container">
                <p>
                  Please do not submit medical questions, personal health
                  information and financial information through this form or
                  email. If this is a life threatening emergency, please call
                  911 immediately.
                </p>
              </div>
            </div>
            <div className="second-container">
              <p>
                Your comments and suggestions help us improve our patient care
                services. If you want to discuss your experience as a patient or
                visitor, please feel free to email us or use the form below to
                let us know your thoughts.
              </p>
              <form action="#" className="form-container">
                <div className="row">
                  <div className="input-row">
                    <IoPersonOutline className="person contact-icon" />
                    <input
                      type="text"
                      id="name"
                      name="name"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="input-row">
                    <SiMinutemailer className="mail contact-icon" />
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="input-row">
                    <IoCallOutline className="phone contact-icon" />
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      placeholder="Phone Number"
                    />
                  </div>
                  <div className="input-row">
                    <PiSpotifyLogoThin className="spotify contact-icon" />
                    <input
                      type="text"
                      id="reason"
                      name="reason"
                      placeholder="Reason For Contacting"
                    />
                  </div>
                </div>
                <div className="input-row">
                  <AiOutlineMessage className="message contact-icon" />
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Enter your message"
                    rows={10}
                  ></textarea>
                </div>
                <button type="submit">Send a message</button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default Contact;
