import React from "react";
import TransparentNav from "../components/TransparentNav";
import PinkBgBtn from "../components/PinkBgBtn";
import "../styles/team/team.css";
import TeamCard from "../components/TeamCard";
import Footer from "../components/Footer";

const team = () => {
  return (
    <div className="main-container">
      <TransparentNav logo="/images/logo.png" />
      <section className="team-hero">
        <div className="container padding-container">
          <div className="team-hero-main-container">
            <h1>Meet Our Team</h1>
            <p>
              To provide the high-quality medical care our patients deserve, we
              rely on dedicated and passionate healthcare workers and staff.
              Meet some of our healthcare heroes and explore how you can be part
              of our team.
            </p>
            {/* <PinkBgBtn to="/careers" text="Join Our Team" /> */}
          </div>
        </div>
      </section>
      <section className="medical-personnel">
        <div className="container padding-container">
          <h1>Medical Personnel</h1>

          <div className="team-card-main-container">
            {/* <h2>First Med Urgent Care</h2> */}
            <div className="team-card-container">
              <TeamCard
                img="/images/heather.webp"
                link="linkedin.com"
                bio={
                  <div className="bio-text">
                    <p>
                      Heather specializes in Family Medicine and Urgent Care,
                      holding board certification from The American Board of
                      Family Medicine. She obtained her MD from the University
                      of Oklahoma Health Science Center, complemented by a BS
                      from the University of Oklahoma.
                    </p>
                    <p>
                      Heather is an active member of professional organizations
                      such as the American Academy of Family Physicians and the
                      Oklahoma Academy of Family Physicians.
                    </p>
                  </div>
                }
                name="Dr. Heather Lynch"
                position="Medical Director (Western Location)"
              />

              <TeamCard
                img="/images/martin.webp"
                link="linkedin.com"
                // bio={
                //   <div className="bio-text">
                //     <p>Bio pending...</p>
                //   </div>
                // }
                name="Martin Kubier"
                position="PA (Walker Location)"
              />
              <TeamCard
                img="/images/kat.webp"
                link="linkedin.com"
                bio={
                  <div className="bio-text">
                    <p>
                      Kat's experience and specialties encompass a broad
                      spectrum, including Family Medicine, Emergency Medicine,
                      Urgent Care, Pediatrics, Cardiology, OB-Gynecology,
                      Neurology, Mental Health, Internal Medicine, General
                      Surgery, and Orthopedics.
                    </p>
                    <p>
                      She holds board certifications from the National
                      Commission of Certification of Physician Assistants and
                      the Oklahoma State Board of Licensure and Supervision.
                      Additionally, Kat possesses professional certifications
                      from the American Academy of Physician Assistants and the
                      Oklahoma Academy of Physician Assistants, underscoring her
                      commitment to excellence in her field.
                    </p>
                  </div>
                }
                name="Kat Williams"
                position="PA ( NW 39th location)

              "
              />
            </div>
          </div>

          <div className="team-card-main-container">
            {/* <h2>First Med Primary Care</h2> */}
            <div className="team-card-container">
              {/* <TeamCard
                img="/images/chesca.webp"
                link="linkedin.com"
                bio={
                  <div className="bio-text">
                    <p>
                      Dr. Chesca Craig-Goodell has practiced medicine for over 2
                      decades. She as born in Shawnee Oklahoma and raised in
                      Seminole Oklahoma where she graduated from Seminole High
                      School. <br /> Dr. Craig-Goodell attended the University
                      of Oklahoma obtaining a Bachelor of Science degree in
                      Biochemistry. In 2002, she graduated from medical school
                      at Oklahoma State University College of Osteopathic
                      Medicine. She completed a Family Practice internship and
                      residency in St. Louis Missouri at Des Peres Hospital.
                    </p>
                    <p>
                      Since 2002, Dr. Craig-Goodell has practiced Family
                      Medicine as well as Urgent Care medicine. She is a member
                      of the American Osteopathic Association and American
                      College of Osteopathic Family Practice.
                    </p>
                    <p>
                      Dr. Craig-Goodell is married to Jeff and they have 2
                      children. She enjoys spending time with her friends and
                      family. Dr. Craig-Goodell says “Family is utmost important
                      to me. I decided to become a doctor at the age of 12 after
                      watching my grandfather fight a heroic battle with cancer.
                      Seeing the struggles my grandfather and family endured
                      motivated me to become a Physician. I strive to provide a
                      knowledgeable, compassionate quality of care, focusing on
                      each patient’s individual needs.”
                    </p>
                  </div>
                }
                name="Dr. Chesca Craig-Goodell"
                position="Physician | First Med Primary Care location

              "
              /> */}
              <TeamCard
                img="/images/no-image-2.webp"
                link="linkedin.com"
                bio={
                  <div className="bio-text">
                    <p>
                      Kassie has been a family nurse practitioner since 2021.
                      She obtained her Master of Science in Nursing with a focus
                      in Family practice from Spring Arbor University. Prior to
                      becoming a nurse practitioner, Kassie was a nurse for 10
                      years. She obtained her Bachelor of Science in Nursing at
                      University of Central Oklahoma in Edmond, Ok.
                    </p>
                    <p>
                      Kassie is married to her husband Chase, and they have one
                      child. She enjoys spending time with her friends and
                      family. Spending time with her son and husband is her
                      biggest priority. She spends her free time enjoying the
                      outdoors with her family skiing, boating, and waiting on
                      the next adventure.
                    </p>
                    <p>
                      Kassie decided to get into nursing after her dad fought a
                      long battle with multiple health issues. The care provided
                      by the healthcare providers inspired her to become a part
                      of healthcare. She strives to provide the same level of
                      compassion and quality of care that was given to her and
                      her family in her everyday care with her patients.
                    </p>
                  </div>
                }
                name="Marysue Farris"
                position="APRN (Western Location)

              "
              />
              {/* <TeamCard
                img="/images/kassie.webp"
                link="linkedin.com"
                bio={
                  <div className="bio-text">
                    <p>
                      Kassie has been a family nurse practitioner since 2021.
                      She obtained her Master of Science in Nursing with a focus
                      in Family practice from Spring Arbor University. Prior to
                      becoming a nurse practitioner, Kassie was a nurse for 10
                      years. She obtained her Bachelor of Science in Nursing at
                      University of Central Oklahoma in Edmond, Ok.
                    </p>
                    <p>
                      Kassie is married to her husband Chase, and they have one
                      child. She enjoys spending time with her friends and
                      family. Spending time with her son and husband is her
                      biggest priority. She spends her free time enjoying the
                      outdoors with her family skiing, boating, and waiting on
                      the next adventure.
                    </p>
                    <p>
                      Kassie decided to get into nursing after her dad fought a
                      long battle with multiple health issues. The care provided
                      by the healthcare providers inspired her to become a part
                      of healthcare. She strives to provide the same level of
                      compassion and quality of care that was given to her and
                      her family in her everyday care with her patients.
                    </p>
                  </div>
                }
                name="Kassie Murray APRN"
                position="Nurse Practitioner | First Med Primary Care location

              "
              /> */}
              <TeamCard
                img="/images/erika.webp"
                link="linkedin.com"
                bio={
                  <div className="bio-text">
                    <p>
                      Erika Somero earned her AAS in Nursing at Oklahoma State
                      University in Okmulgee and then completed a two-year RN
                      residency at OU Medical Center specializing in trauma and
                      orthopedics. She went on to earn her BA in Nursing at
                      Western Governors University and her MSN as a Family Nurse
                      Practitioner from Maryville University. Having spent time
                      on the ortho/trauma floors of most of the local hospital
                      systems, she brings an extensive skill set to the urgent
                      care setting. She enjoys the variety of patients, medical
                      conditions and the fast pace that Urgent Care provides.
                    </p>
                    <p>
                      Erika has been married to Stephanie for 3 years and
                      together they have a blended family that includes 5
                      children, 1 grandchild with a second on the way. She
                      enjoys spending time with her family, pets, gardening,
                      painting, reading, watching sci-fi, and going on trips.
                    </p>
                  </div>
                }
                name="Erika Somero"
                position="APRN (Walker Location)"
              />
              <TeamCard
                img="/images/no-image-2.webp"
                link="linkedin.com"
                // bio={
                //   <div className="bio-text">
                //     <p>Bio pending...</p>
                //   </div>
                // }
                name="Barome Enoweyere"
                position="APRN (NW 39th Location)

              "
              />
            </div>
          </div>

          <div className="team-card-main-container">
            {/* <h2>Cohesive Family Medicine</h2> */}
            <div className="team-card-container">
              {/* <TeamCard
                img="/images/david.webp"
                link="linkedin.com"
                bio={
                  <div className="bio-text">
                    <p>
                      David stands as a dedicated practitioner with a focus on
                      Family Practice and Urgent Care. Holding esteemed board
                      certification from the American Osteopathic Board of
                      Family Physicians, he brings a wealth of expertise to his
                      patients' care.
                    </p>
                    <p>
                      His journey began at Oklahoma State University College of
                      Osteopathic Medicine, where he earned his Doctor of
                      Osteopathic Medicine degree, alongside a Bachelor of
                      Science from Northeastern State University.
                    </p>
                    <p>
                      Actively involved in his professional community, he holds
                      memberships in the American Academy of Osteopathic Family
                      Physicians, American Osteopathic Association, Oklahoma
                      Osteopathic Association, and the Urgent Care Association,
                      embodying his commitment to ongoing learning and
                      collaboration in the field.
                    </p>
                  </div>
                }
                name="Dr. David Hopkins"
                position="Physician | NW 39th Location"
              /> */}
            </div>
          </div>

          <div className="join-our-team-btn">
            <PinkBgBtn to="/careers" text="Join Our Team" />
          </div>
        </div>
      </section>
      <section className="board-of-directors">
        <div className="container padding-container">
          <h1>Board of Directors</h1>
          <div className="team-card-container">
            <TeamCard
              link="linkedin.com"
              bio={
                <div className="bio-text">
                  <p>
                    Godwin Feh, with more than two decades of experience as a
                    patient-focused healthcare leader with proven expertise in
                    acute care, staff development, and family advocacy, founded
                    Cohesive Healthcare in 2016.
                  </p>
                  <p>
                    Founder, President and CEO of Cohesive Healthcare the
                    company has grown over the years under Feh’s direction. Feh
                    continues to work in collaboration with each hospital and
                    medical staff, hospital board, and the individual
                    communities Cohesive Health serves to implement and achieve
                    the strategic goals of both organizations. Feh directs the
                    strategic planning of operations management within each
                    respective facility and oversees the formulation of master
                    budgets and budgetary allocations.
                  </p>
                  <p>
                    Before founding Cohesive Healthcare, Feh served in
                    leadership positions in several long-term acute care
                    hospitals and critical care hospitals. During his tenure at
                    various healthcare facilities, Feh was widely recognized as
                    an excellent care provider, patient advocate, and hospital
                    administrator.
                  </p>
                  <p>
                    Feh received his Bachelor of Science in Nursing and his
                    Master of Business Administration from St. Gregory
                    University.
                  </p>
                </div>
              }
              name="Godwin Feh"
              position="CEO & Founder"
              img="/images/Godwin.webp"
            />
            <TeamCard
              link="linkedin.com"
              // bio={
              //   <div className="bio-text">
              //     <p>Bio pending...</p>
              //   </div>
              // }
              name="Randy Kerr"
              position="VP of Operations"
              img="/images/Randy.webp"
            />
            <TeamCard
              link="linkedin.com"
              bio={
                <div className="bio-text">
                  <p>
                    Steve Hockert, FACHE has over 25 years of experience in the
                    healthcare sector. He has served in a variety of leadership
                    roles over the course of the last 20 years. Entities under
                    his leadership have earned many accolades including multiple
                    Hospital of the Year awards, Most Improved Hospital, and
                    Patient’s Choice Awards.
                  </p>
                  <p>
                    Steve completed his Master’s Degree in Healthcare
                    Administration in 2007 and is currently a Fellow of the
                    American College of Healthcare Executives. Outside of work,
                    he enjoys the outdoors with his wife and three daughters and
                    volunteers for adolescent mental health causes.
                  </p>
                </div>
              }
              name="Steve Hockert"
              // position="Pending..."
              img="/images/no-image-2.webp"
            />
          </div>
        </div>
      </section>
      <Footer logo="/images/logo.png" />
    </div>
  );
};

export default team;
