import React from "react";
import GeneralNavBar from "../components/GeneralNavBar";
import PinkBgBtn from "../components/PinkBgBtn";
import BorderBtn from "../components/BorderBtn";
import "../styles/urgent-care/urgent-care.css";
import FacilitiesCard from "../components/FacilitiesCard";
import Testimonial from "../components/Testimonial";
import HealthPartner from "../components/HealthPartner";
import { PiStethoscopeBold } from "react-icons/pi";
import { CiMedicalCross } from "react-icons/ci";
import { PiTimer } from "react-icons/pi";
import { BsCreditCard2Back } from "react-icons/bs";
import Insurance from "../components/Insurance";
import Footer from "../components/Footer";
import FacilityPopup from "../components/FacilitiesPopup";
import { useState } from "react";

const UrgentCare = () => {
  const [popupInfo, setPopupInfo] = useState(null);

  const openPopup = (info) => {
    setPopupInfo(info);
  };

  const closePopup = () => {
    setPopupInfo(null);
  };
  return (
    <div className="main-container">
      <GeneralNavBar logo="/images/urgent-care-logo.png" />
      <section className="urgent-hero ">
        <div className="container padding-container">
          <div className="urgent-hero-content">
            <div className="urgent-hero-img-container">
              <img src="/images/first_med_white_logo.svg" alt="logo" />
            </div>
            <p>
              Our mission is to uniquely connect patients to convenient,
              compassionate care in the communities we serve.
            </p>
            <div className="urgent-hero-button">
              <PinkBgBtn to="#facility" text="Book An Appointment" />
              <BorderBtn
                target="_blank"
                to="https://firstmedurgent.securepayments.cardpointe.com/pay"
                text="Pay Your Bill"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="visit-us">
        <div className="container padding-container">
          <h1>Visit Us</h1>
          <div className="visit-us-main-container">
            <div className="visit-us-img-container">
              <img src="/images/visit-us.png" alt="Lady veiwing xray result" />
            </div>
            <div className="visit-us-text-content">
              <h1>"Walk-Ins Welcome"</h1>
              <p>
                <div>All Urgent locations open everyday</div> <br />
                <span>8am to 7pm</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="visit-us-facility" id="facility">
        <div className="container padding-container">
          <h1>Our facilities</h1>
          <p>
            With 3 Urgent Care Oklahoma City metro area locations to serve you –
            you’re more than a patient, you’re our neighbor. Skip the line by
            booking an appointment or just walk in.
          </p>
          <div className="facilities-card-container">
            <FacilitiesCard
              facility="Urgent Care - Northwest"
              address="4510 NW 39TH ST."
              location="OKC, OK 73122"
              img="/images/urgent-care-full-facility.webp"
              to="https://www.solvhealth.com/r/book-online/gZmPXp/yRSgxxYGPtbuXrGvTi7rE/slots/today"
              onClick={() =>
                openPopup({
                  facility: "Urgent Care - Northwest",
                  address: "4510 NW 39TH ST.",
                  location:
                    "Same-day Appointments Virtual Visit Rapid Covid Antigen Testing Walk-ins welcome Onsite Lab Onsite Xray Insurance Accepted Self Pay Accepted",
                  apiEndpoint:
                    "https://manage.solvhealth.com/partner/next-available/gZmPXp?partner_id=gZmPXp",

                  operationHours: (
                    <p>
                      TRY OUT A VIRTUAL VISIT AND SEE A PROVIDER FROM THE
                      COMFORT OF YOUR HOME! <br />
                      Normal business hours: 8:00 a.m. to 7:00 p.m | Daily
                    </p>
                  ),
                })
              }
            />
            <FacilitiesCard
              facility="Urgent Care - South Walker"
              address="7807 S Walker Ave"
              location="OKC, OK 73139"
              img="/images/both-primary-urgent.webp"
              to="https://www.solvhealth.com/r/book-online/pYOeR0/AkOpTB5ecPbcEg7RPsfa5/slots/today"
              onClick={() =>
                openPopup({
                  facility: "Urgent Care - South Walker",
                  address: "7807 S Walker Ave",
                  location:
                    "Same-day Appointments Virtual Visit Rapid Covid Antigen Testing Walk-ins welcome Onsite Lab Onsite Xray Insurance Accepted Self Pay Accepted DOT Physicals",
                  operationHours: (
                    <p>
                      TRY OUT A VIRTUAL VISIT AND SEE A PROVIDER FROM THE
                      COMFORT OF YOUR HOME! <br />
                      Normal business hours: 8:00 a.m. to 7:00 p.m | Daily
                    </p>
                  ),

                  apiEndpoint:
                    "https://manage.solvhealth.com/partner/next-available/pYOeR0?partner_id=pYOeR0",
                })
              }
            />
            <FacilitiesCard
              facility="Urgent Care - South Western"
              address="11521 S. Western"
              location="OKC, OK 73170"
              img="/images/urgent-care-facility.webp"
              to="https://www.solvhealth.com/r/book-online/AMMOwO/zRvYQ9vRwzaDClnbRDFbA/slots/today"
              onClick={() =>
                openPopup({
                  facility: "Urgent Care - South Western",
                  address: "11521 S. Western",
                  location:
                    "Same-day Appointments Virtual Visit Rapid Covid Antigen Testing Walk-ins welcome Onsite Lab Onsite Xray Insurance Accepted Self Pay Accepted",

                  apiEndpoint:
                    "https://manage.solvhealth.com/partner/next-available/AMMOwO/C36fO4n9O1edH8hKXeV3H/slots/today?partner_id=AMMOwO/C36fO4n9O1edH8hKXeV3H/slots/today",
                  operationHours: (
                    <p>
                      TRY OUT A VIRTUAL VISIT AND SEE A PROVIDER FROM THE
                      COMFORT OF YOUR HOME! <br />
                      Normal business hours: 8:00 a.m. to 5:00 p.m |
                      Monday-Friday
                    </p>
                  ),
                  goToDirectLink:
                    "www.solvhealth.com/r/book-online/AMMOwO/RgR7-Pkjin-zt-EOT_Foa/slots/today",
                })
              }
            />
          </div>
          {popupInfo && <FacilityPopup {...popupInfo} onClose={closePopup} />}
        </div>
      </section>
      <section className="where-to-go">
        <div className="container padding-container">
          <h1>Know where to go</h1>
          <p>
            More than half of emergency room visits are for non-emergency
            situations. Here’s a guide to help you know where to go.
          </p>
          <div className="where-main-container">
            <div className="where-card">
              <div className="row">
                <div className="stethoscope-icon-container">
                  <PiStethoscopeBold className="stethoscope" />
                </div>
                <h3>What is Urgent Care?</h3>
              </div>
              <p>
                Urgent Care is a service designed for immediate medical
                attention and treatment. Urgent Care services cover a variety of
                non-life threatening conditions and other general medical needs
                that arise in unforeseen situations.
              </p>

              <h4>
                Visit FirstMed for non-life threatening but time sensitive
                conditions or injuries.
              </h4>
              <div className="card-bottom-row">
                <div className="bottom-row-first">
                  <div className="time-icon-container">
                    <PiTimer className="urgent-timer" />
                  </div>
                  <h5>Average Visit Time</h5>
                  <span>&gt;1 Hour </span>
                </div>
                <div className="bottom-row-second">
                  <div className="card-icon-container">
                    <BsCreditCard2Back className="urgent-card" />
                  </div>
                  <h5>Average Cost</h5>
                  <span>&gt;$120 </span>
                </div>
              </div>
            </div>
            <div className="where-card">
              <div className="row">
                <div className="cross-icon-container">
                  <CiMedicalCross className="cross" />
                </div>
                <h3>What is Emergency Care?</h3>
              </div>
              <p>
                Emergency care includes those medical services required for the
                immediate diagnosis and treatment of medical conditions that, if
                not immediately diagnosed and treated, could lead to serious
                disability or life threatening circumstances.
              </p>

              <h4>
                For emergency conditions immediately call 911 or visit your
                nearest Emergency Room.
              </h4>
              <div className="card-bottom-row">
                <div className="bottom-row-first">
                  <div className="time-icon-container">
                    <PiTimer className="emergency-timer" />
                  </div>
                  <h5>Average Visit Time</h5>
                  <span>&gt;3 Hours </span>
                </div>
                <div className="bottom-row-second">
                  <div className="card-icon-container">
                    <BsCreditCard2Back className="emergency-card" />
                  </div>
                  <h5>Average Cost</h5>
                  <span>&gt;$1000 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Insurance />
      <HealthPartner />
      <Testimonial />
      <Footer logo="/images/urgent-care-logo.png" />
    </div>
  );
};

export default UrgentCare;
