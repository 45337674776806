import React from "react";
import "../styles/careers.css";
import { CiLocationOn } from "react-icons/ci";
import { CiClock2 } from "react-icons/ci";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useState } from "react";
import { HiXMark } from "react-icons/hi2";

const CareerCard = (props) => {
  const [showPopup, setShowPopup] = useState(false);

  const showPopupFn = () => {
    setShowPopup(true);
  };
  const hidePopopFn = () => {
    setShowPopup(false);
  };
  return (
    <>
      {showPopup && <div className="popup-overlay" onClick={hidePopopFn}></div>}
      <div className="career-card">
        <div className="row">
          <div className="col-1">
            <h3 dangerouslySetInnerHTML={{ __html: props.jobPosition }}></h3>
            <div className="description">
              <div
                dangerouslySetInnerHTML={{ __html: props.jobDescription }}
              ></div>
              <div
                dangerouslySetInnerHTML={{ __html: props.jobRequirements }}
                className="requirements"
              ></div>
            </div>

            <div className="row-2">
              <div className="icon-text">
                <CiLocationOn className="location" />
                <p>{props.jobLocation}</p>
              </div>

              <div className="icon-text">
                <CiClock2 className="clock" />
                <p>{props.time}</p>
              </div>
            </div>
          </div>

          <div className="col-2">
            <Link to={props.link} className="apply-now">
              <span>Apply now </span>

              <BsArrowUpRight className="icon" />
            </Link>
            <span onClick={showPopupFn}>Read more</span>
          </div>
        </div>
        <hr></hr>
      </div>
      {showPopup && (
        <div className="job-popup">
          <HiXMark className="x-icon" onClick={hidePopopFn} />
          <h2>Job Details</h2>
          <h1>{props.jobPosition}</h1>
          <div className="row-2">
            <div className="icon-text">
              <CiLocationOn className="location" />
              <p>{props.jobLocation}</p>
            </div>

            <div className="icon-text">
              <CiClock2 className="clock" />
              <p>{props.time}</p>
            </div>
          </div>
          <div className="description">
            <div
              dangerouslySetInnerHTML={{ __html: props.jobDescription }}
            ></div>
            <div
              dangerouslySetInnerHTML={{ __html: props.jobRequirements }}
              className="requirements"
            ></div>
          </div>
          <Link to={props.link} className="apply-now">
            <span>Apply now </span>

            <BsArrowUpRight className="icon" />
          </Link>
        </div>
      )}
    </>
  );
};

export default CareerCard;
